import React from 'react';

import Homepage from '../templates/Homepage';
import { useCurrentLanguage } from '../components/LanguageProvider';

const Home = ({ location }) => {
  const currentLang = useCurrentLanguage();

  return (
    <Homepage
      location={location}
      seo={{ canonical: `https://health.sensape.com/${currentLang}` }}
    />
  );
};

Home.displayName = 'Home';

export default Home;
